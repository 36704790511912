import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { buildConfig, BuildType } from '../../../BuildConfig';
import { store } from '../../../store';
import { AuthAction } from '../../../store/auth';
import { BaseResponse } from '../chatApi';

interface NeurodocGoTestApi {
  getTestNeurodoc(): Promise<BaseResponse<any>>;
}

export class NeurodocGoTest implements NeurodocGoTestApi {
  constructor(public readonly http: AxiosInstance) {}

  getTestNeurodoc() {

    return this.http
      .post('/neurodoc_go_test/GetTest', {
        stage_number: 7,
        task_id: 7,
      })
      .then(res => res.data);
  }

  static createInstance() {
    const axiosInstance = axios.create({
      baseURL: buildConfig.buildType === BuildType.production ? '/api' : 'http://192.168.8.77:9101/',
    });

    axiosInstance.interceptors.request.use((value: AxiosRequestConfig) => {
      const jwtToken = store.getState().auth.jwtToken;
      value.headers['Authorization'] = jwtToken ? `Bearer ${jwtToken}` : undefined;
      // value.headers['session_id'] = 'fc97ee31-2970-4b7f-b5f4-60644308819b';
      // value.headers['sit_task_id'] = '1';

      // todo use redux store for save this data persistent
      if (value.data !== null && typeof value.data === 'object') {
        value.data.apiKey = 'ygyhghkjjkghxrzawedfh7654fhukjkki';
        value.data.ip = '192.168.1.222';
        value.data.userId = 'c2d9e41e-4935-4678-9f23-e56a8a626573';
      }
      return value;
    });

    const createError = (response: AxiosResponse<any>) => {
      const message = response.data?.description && response.data?.description?.length > 0 ? response.data.description.map((it: any) => it.message).join('\n') : null;

      return new Error(message || 'Ошибка, попробуйте позже');
    };

    axiosInstance.interceptors.response.use(
      (res: AxiosResponse) => {
        if (res.data.isSuccessefull === false) {
          return Promise.reject(createError(res));
        }
        return res;
      },
      err => {
        if (err.response.status === 401) {
          store.dispatch({ type: AuthAction.LOGOUT, data: null });
        }
        return Promise.reject(createError(err));
      }
    );

    return new NeurodocGoTest(axiosInstance);
  }
}
