import { AxiosResponse } from 'axios';
import { ChatMessage } from '../../types';
import {
  Medicine_ResponseData,
  AuthenticateRequest,
  BaseResponse,
  Rating_BestUser,
  Rating_Activity,
  Rating_Line,
  Constructors_Task,
  Constructors_Task_Send,
  Constructors_Communication,
  ChatApi,
  JournalHistory_ResponseData,
  JwtToken,
  RegistrationRequest,
  SendMessage_ResponseData,
  Task_ResponseData,
  TaskProceed_ResponseData,
  TypeOfDifficultLevel_ResponseData,
  CheckJournalHistory_ResponseData,
  GroupOfResearch_ResponseData,
  ResearchItem_RequestData,
  ResearchResults_ResponseData,
  ResearchResultRootGroup,
  TaskResult_ResponseData,
  DiagnosisTypes_ResponseData,
  Diagnosis_ReqData,
  DiagnosisFrom_ResponseData,
  TypeOfResearchSearchResult_Response,
  ReleaseForm,
  GroupOfMedicine,
  ReceptionMethod,
  TimeOfReceipt,
  ConnectionWithFood,
  ConnectionWithSleep,
  AdditionalRestriction,
  ReceptionPeriod_NetData,
  PrescribedMedication__NetData,
  PrescribedMedicationResult__ResponseData,
  TestingStage,
  PrescribedMedication__ResponseData,
  DietType_ResponseData,
  FoodMedication_GetResponse_NetData,
  DosageUnit_ResponseData,
  NotMedicalFlag_ResponseData,
  NotMedicalType_ResponseData,
  NotMedicalMedication_NetData,
  MedicalResult_ResponseData,
  DietMedication_CreateUpdateRequest_NetData,
  ScheduleMedication_CreateUpdate_NetData,
  DietMedication_GetResponse_NetData,
  TargetUseType_NetData,
  ScheduleMedication_GetResponse_NetData,
  FoodMedication_CreateUpdate_NetData,
  AlternativeMedicationType_GetResponse,
  ActiveMedication_CreateUpdate_NetData,
  ActiveMedication_GetResponse_NetData,
  PassiveMedication_CreateUpdate_NetData,
  PassiveMedication_GetResponse_NetData,
  PassiveActiveTreatment_GetResponse_NetData,
  BodyPartImage_GetResponse_NetData,
  InspectionMethod_GetResponse_NetData,
  InspectionSide_GetResponse_NetData,
  PartOfBody_GetResponse_NetData,
  PointOfView_GetResponse_NetData,
  University_Level,
  Patient_Model,
  Type_Of_Patient_Status,
  Constructors_Additional,
  BodyPartImage_Props,
  PointsOfView_Props,
  TaskRating_ResponseData,
  Constructors_PatientParams,
  Constructors_ClinicalRelevance,
  Type_DescriptionList,
  Type_ResearchDefaultByTypeId,
  IIllness,
  type_Anamnesis,
  Constructors_ReferenceDescription,
  Constructors_ConnectionWithIllness,
  Constructors_UrinePressure,
  Constructors_UrineColor,
  Constructors_UrineImpurity,
  Constructors_UrineSmell,
  Constructors_UrineTransparency,
  Constructors_UrineFoam,
  Constructors_UrineComfort,
  Constructors_UrineAdditionalFenomen,
  Constructors_ExcrementColor,
  Constructors_ExcrementComfort,
  Constructors_ExcrementPhoto,
  Constructors_ExcrementSmell,
  currentAnswers,
  Constructors_ClinicalDiagnosis,
  Constructors_ClinicalDiagnosisMedicalDictionary,
  Constructors_ClinicalDiagnosisInformationLevel,
  ClinicalDiagnosisTaskProceed,
  Rheumatology_State,

} from './chatApi';
import { delay } from '../../common/Utils';
import { range } from 'ramda';

const task = {
  id: 1,
  uid: 'uid',
  description: '',
  active: true,
  name: '',
  penalty: 1,
  created: '',
  creator: '',
  typeOfDifficultLevelId: 1,
  conscious: true,
  medicineId: 1,
  typeOfDifficultLevel: {
    name: '',
    active: true,
    id: 1,
    code: '',
    coefficient: 1,
  },
  patientModel: {
    patient: {
      id: 1,
      firstName: 'Вася',
      photo: 'https://24smi.org/public/media/celebrity/2017/10/27/g0Tf3uZaLmbE_vasya-oblomov.jpg',
      secondName: 'Обломов',
      gender: 'м',
    },
  },
};

function wrapToResponse<T>(reqData: T): BaseResponse<T> {
  return {
    success: true,
    description: null,
    result: reqData,
  };
}

function generateRandomChatHistory(): Array<JournalHistory_ResponseData> {
  return Array.from(Array(20).keys()).map(i => {
    return {
      createDate: new Date(),
      id: i,
      request: { languageCode: 'ru', unMarkedText: `${i}` },
      response: { penalty: i, points: i, text: `${i}`, tdMediaId: i },
    };
  });
}

function generateRandomCheckHistory(): Array<CheckJournalHistory_ResponseData> {
  return Array.from(Array(20).keys()).map(i => {
    return {
      createDate: new Date(),
      id: i,
      request: { languageCode: 'ru', unMarkedText: `${i}` },
      response: { penalty: i, points: i, text: `${i}`, tdMediaId: i },
    };
  });
}

function* generateResearchResult() {
  for (let i = 0; true; i++) {
    const rootGroup = i % 2 === 0 ? ResearchResultRootGroup.Laboratory : ResearchResultRootGroup.Instrumental;
    const text =
      rootGroup === ResearchResultRootGroup.Instrumental ? `https://picsum.photos/200/300?random=${i}` : `{ "name":"количество лимфоцитов", "value":"2", "reference":"19-37", "unit":"%" }`;
    yield {
      type: i % 3 === 0 ? 'Image' : 'String',
      penalty: i,
      text: text,
      title: `title ${i % 4}`,
      points: i,
      typeOfResearchId: i,
      group: rootGroup,
    } as ResearchResults_ResponseData;
  }
}

const researchResultGenerator: Generator<ResearchResults_ResponseData, void, unknown> = generateResearchResult();

// eslint-disable-next-line
function* generateGroupOfResearch() { }

export class MockChatApiImpl implements ChatApi {
  updateAnswer(body: currentAnswers): Promise<BaseResponse<currentAnswers>> {
    throw new Error('Method not implemented.');
  }
  [x: string]: any;
  getThemesGroup() {
    throw new Error('Method not implemented.');
  }

  getAllSpecialists() {
    throw new Error('Method not implemented.');
  }

  createSpecialist(taskId: number, id: number) {
    throw new Error('Method not implemented.');
  }

  getCheckAnswer(id: number) {
    throw new Error('Method not implemented.');
  }

  getAnswerSpecialists(task: number) {
    throw new Error('Method not implemented.');
  }

  getAllChampionships() {
    throw new Error('Method not implemented.');
  }

  getRegisterChamp(id: number) {
    throw new Error('Method not implemented.');
  }

  saveProcced(task: number, champ: number, healing: boolean) {
    throw new Error('Method not implemented.');
  }

  registeredUsers(id: number) {
    throw new Error('Method not implemented.');
  }

  userListPoint(id: number) {
    throw new Error('Method not implemented.');
  }

  getAllUsersAdmin(logins?: Array<string>, trainerIds?: Array<number>, orgIds?: Array<number>): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getUserAdmin(login: string): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  dataTable(sData: any, poData: any): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  getInfoRole(userId: any): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  createUserAdmin(login: string, firstName: string, lastName: string, patronymic: string, email: string, imageUrl: string, langKey: string, password: string): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  editUserAdmin(login: string, firstName: string, lastName: string, patronymic: string, email: string, imageUrl: string, langKey: string, activate: boolean): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  savePassword(currentPassword: string, newPassword: string): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  createRole(userId: any): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  addRole(userId: number, role: any): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  removeRole(userId: number, role: any): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  addOrg(): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  addTrn(): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  deleteOrg(userId: any, orgId: any): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  deleteTrn(userId: any, orgId: any): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  executeOrg(id: number, answer: string, actionAnswer: boolean): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  executeTrn(id: number, answer: string, actionAnswer: boolean): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  allOrg(data: string | number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  allTrn(data: string | number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  requestOrg(userId: number, orgId: number, action: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  requestTrn(userId: number, orgId: number, action: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  listRoles(): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  descriptionRole(role: string): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  addDescription(role: string, descrip: string): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  updateDescription(id: number, role: string, descrip: string): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  deleteDescription(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  adminRating(): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  updataAdminRating(id: number, description: string, active: boolean): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  allStudents(): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  allTeachers(): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getUsersMarks(): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getUserTrainers(): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented')
  }

  getAllSpeciality(): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getAllSpecialityMy(): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  createSpeciality(studentSpecialityId: number, userId: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  deleteSpeciality(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getAllOrgProfile(): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getOrgMy(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getAllTeacher(): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getTeacherMy(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  createTeacher(teacherId: number, userId: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  deleteTeacher(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getAllTrnProfile(): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getTrnMy(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getRating(stop: any, start: any, id: any, org: any, trn: any, option: any): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }


  getResearchDefaultByTypeId(id: number): Promise<BaseResponse<Type_ResearchDefaultByTypeId[]>> {
    throw new Error('Method not implemented.');
  }

  loadQuestionsStress(phrasesId: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  loadQuestionsStep(phrasesId: number, questionNumber: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  loadAnalysisStress(phraseId: any): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  toggleStress(login: string): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  stressHistory(body: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  createParametersForDefaultResearch(body: any): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getResearchDefault(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  updateResearchDefault(id: number, body: any): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  deleteParametersForDefaultResearch(id: any): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getResearchUnit(): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  createResearchDefault(body: any): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getParametersForDefaultResearch(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsDescriptionList(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  createConstructorsDescriptionList(body: Type_DescriptionList): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  updateConstructorsDescriptionList(id: number, body: Type_DescriptionList): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  deleteConstructorsDescriptionList(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  updateConstructorsPatientParams(id: number, params: Constructors_PatientParams): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  createConstructorsPatientParams(params: Constructors_PatientParams): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  deleteConstructorsPatientParams(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsPatientParams(): Promise<BaseResponse<Constructors_PatientParams[]>> {
    throw new Error('Method not implemented.');
  }
  getConstructorsClinicalRelevance(): Promise<BaseResponse<Constructors_ClinicalRelevance[]>> {
    throw new Error('Method not implemented.');
  }
  updateConstructorsClinicalRelevance(id: number, params: Constructors_ClinicalRelevance): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  deleteConstructorsClinicalRelevance(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  createConstructorsClinicalRelevance(params: Constructors_ClinicalRelevance): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  getConstructorsReferenceDescription(): Promise<BaseResponse<Constructors_ReferenceDescription[]>> {
    throw new Error('Method not implemented.');
  }
  updateConstructorsReferenceDescription(id: number, params: Constructors_ReferenceDescription): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  deleteConstructorsReferenceDescription(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  createConstructorsReferenceDescription(params: Constructors_ReferenceDescription): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsConnectionWithIllness(): Promise<BaseResponse<Constructors_ConnectionWithIllness[]>> {
    throw new Error('Method not implemented.');
  }
  updateConstructorsConnectionWithIllness(id: number, params: Constructors_ConnectionWithIllness): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  deleteConstructorsConnectionWithIllness(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  createConstructorsConnectionWithIllness(params: Constructors_ConnectionWithIllness): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsUrinePressure(): Promise<BaseResponse<Constructors_UrinePressure[]>> {
    throw new Error('Method not implemented.');
  }
  updateConstructorsUrinePressure(id: number, params: Constructors_UrinePressure): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  deleteConstructorsUrinePressure(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  createConstructorsUrinePressure(params: Constructors_UrinePressure): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsUrineColor(): Promise<BaseResponse<Constructors_UrineColor[]>> {
    throw new Error('Method not implemented.');
  }
  updateConstructorsUrineColor(id: number, params: Constructors_UrineColor): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  deleteConstructorsUrineColor(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  createConstructorsUrineColor(params: Constructors_UrineColor): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsUrineImpurity(): Promise<BaseResponse<Constructors_UrineImpurity[]>> {
    throw new Error('Method not implemented.');
  }
  updateConstructorsUrineImpurity(id: number, params: Constructors_UrineImpurity): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  deleteConstructorsUrineImpurity(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  createConstructorsUrineImpurity(params: Constructors_UrineImpurity): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsUrineSmell(): Promise<BaseResponse<Constructors_UrineSmell[]>> {
    throw new Error('Method not implemented.');
  }
  updateConstructorsUrineSmell(id: number, params: Constructors_UrineSmell): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  deleteConstructorsUrineSmell(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  createConstructorsUrineSmell(params: Constructors_UrineSmell): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsUrineTransparency(): Promise<BaseResponse<Constructors_UrineTransparency[]>> {
    throw new Error('Method not implemented.');
  }
  updateConstructorsUrineTransparency(id: number, params: Constructors_UrineTransparency): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  deleteConstructorsUrineTransparency(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  createConstructorsUrineTransparency(params: Constructors_UrineTransparency): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsUrineFoam(): Promise<BaseResponse<Constructors_UrineFoam[]>> {
    throw new Error('Method not implemented.');
  }
  updateConstructorsUrineFoam(id: number, params: Constructors_UrineFoam): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  deleteConstructorsUrineFoam(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  createConstructorsUrineFoam(params: Constructors_UrineFoam): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsUrineComfort(): Promise<BaseResponse<Constructors_UrineComfort[]>> {
    throw new Error('Method not implemented.');
  }
  updateConstructorsUrineComfort(id: number, params: Constructors_UrineComfort): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  deleteConstructorsUrineComfort(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  createConstructorsUrineComfort(params: Constructors_UrineComfort): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsUrineAdditionalFenomen(): Promise<BaseResponse<Constructors_UrineAdditionalFenomen[]>> {
    throw new Error('Method not implemented.');
  }
  updateConstructorsUrineAdditionalFenomen(id: number, params: Constructors_UrineAdditionalFenomen): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  deleteConstructorsUrineAdditionalFenomen(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  createConstructorsUrineAdditionalFenomen(params: Constructors_UrineAdditionalFenomen): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsExcrementColor(): Promise<BaseResponse<Constructors_ExcrementColor[]>> {
    throw new Error('Method not implemented.');
  }
  updateConstructorsExcrementColor(id: number, params: Constructors_ExcrementColor): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  deleteConstructorsExcrementColor(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  createConstructorsExcrementColor(params: Constructors_ExcrementColor): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsExcrementComfort(): Promise<BaseResponse<Constructors_ExcrementComfort[]>> {
    throw new Error('Method not implemented.');
  }
  updateConstructorsExcrementComfort(id: number, params: Constructors_ExcrementComfort): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  deleteConstructorsExcrementComfort(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  createConstructorsExcrementComfort(params: Constructors_ExcrementComfort): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsExcrementPhoto(): Promise<BaseResponse<Constructors_ExcrementPhoto[]>> {
    throw new Error('Method not implemented.');
  }
  updateConstructorsExcrementPhoto(id: number, params: Constructors_ExcrementPhoto): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  deleteConstructorsExcrementPhoto(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  createConstructorsExcrementPhoto(params: Constructors_ExcrementPhoto): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsExcrementSmell(): Promise<BaseResponse<Constructors_ExcrementSmell[]>> {
    throw new Error('Method not implemented.');
  }
  updateConstructorsExcrementSmell(id: number, params: Constructors_ExcrementSmell): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  deleteConstructorsExcrementSmell(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  createConstructorsExcrementSmell(params: Constructors_ExcrementSmell): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }


  setTaskRating(grade: number, description: string): Promise<BaseResponse<TaskRating_ResponseData>> {
    throw new Error('Method not implemented.');
  }

  markPointClicked(pointId: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getInspectionSides(): Promise<BaseResponse<InspectionSide_GetResponse_NetData[]>> {
    throw new Error('Method not implemented.');
  }

  getInspectDionSides(): Promise<BaseResponse<InspectionSide_GetResponse_NetData[]>> {
    throw new Error('Method not implemented.');
  }

  getPartsOfBody(): Promise<BaseResponse<PartOfBody_GetResponse_NetData[]>> {
    throw new Error('Method not implemented.');
  }

  getInspectionMethods(): Promise<BaseResponse<InspectionMethod_GetResponse_NetData[]>> {
    throw new Error('Method not implemented.');
  }

  getPointsOfView(inspectionsideid: string, partofbodyid: string, inspectionmethodid: string): Promise<BaseResponse<PointOfView_GetResponse_NetData[]>> {
    throw new Error('Method not implemented.');
  }

  getBodyPartImage(inspectionsideid: string, partofbodyid: string, inspectionmethodid: string, inspectionTask: string): Promise<BaseResponse<BodyPartImage_GetResponse_NetData[]>> {
    throw new Error('Method not implemented.');
  }

  getAllRheumatologyState(): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getUserInfo(): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getUserStatus(): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getUserRating(startTime: string, endTime: string): Promise<BaseResponse<number>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsTasks(): Promise<BaseResponse<Array<Constructors_Task>>> {
    throw new Error('Method not implemented.');
  }

  updateConstructorsTasks(task: Constructors_Task_Send): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  createConstructorsTasks(task: Constructors_Task_Send): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  deleteConstructorsTasks(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  updateConstructorsTaskDetail(illness: IIllness): Promise<BaseResponse<IIllness>> {
    throw new Error('Method not implemented.');
  }

  createConstructorsTaskDetail(illness: IIllness): Promise<BaseResponse<IIllness>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsTaskDetail(id: number): Promise<BaseResponse<Array<any>>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsCommunications(): Promise<BaseResponse<Array<Constructors_Communication>>> {
    throw new Error('Method not implemented.');
  }

  updateConstructorsCommunications(id: number, communication: Constructors_Communication): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  createConstructorsCommunications(communication: Constructors_Communication): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  deleteConstructorsCommunications(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsInspectionside(): Promise<BaseResponse<Array<any>>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsPartOfBody(): Promise<BaseResponse<Array<any>>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsInspectionMethods(): Promise<BaseResponse<Array<any>>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsPointsOfView(taskId: number): Promise<BaseResponse<Array<PointOfView_GetResponse_NetData>>> {
    throw new Error('Method not implemented.');
  }

  getUserTaskProceedJoint(): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  createRheumatologyState(body: Rheumatology_State): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  getRheumatologyUserTaskProceedState(): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }
  createRheumatologyUserTaskProceedState(state: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  updateRheumatologyState(body: Rheumatology_State): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  deleteRheumatologyState(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getRheumatologyState(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  createConstructorsPointsOfView(pointsOfView: PointsOfView_Props): Promise<BaseResponse<Array<any>>> {
    throw new Error('Method not implemented.');
  }

  updateConstructorsPointsOfView(pointsOfView: PointsOfView_Props): Promise<BaseResponse<Array<any>>> {
    throw new Error('Method not implemented.');
  }

  deleteConstructorsPointsOfView(id: number): Promise<BaseResponse<Array<any>>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsBodyPartImage(id: number): Promise<BaseResponse<Array<any>>> {
    throw new Error('Method not implemented.');
  }

  createConstructorsBodyPartImage(bodyPart: BodyPartImage_Props): Promise<BaseResponse<Array<any>>> {
    throw new Error('Method not implemented.');
  }

  updateConstructorsBodyPartImage(bodyPart: BodyPartImage_Props): Promise<BaseResponse<Array<any>>> {
    throw new Error('Method not implemented.');
  }

  deleteConstructorsBodyPartImage(id: number): Promise<BaseResponse<Array<any>>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsTypeOfResearch(): Promise<BaseResponse<Array<any>>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsGroupTypeResearch(id: number): Promise<BaseResponse<Array<any>>> {
    throw new Error('Method not implemented.');
  }

  updateConstructorsTypeOfResearch(id: number, typeOfResearch: Constructors_Additional): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  createConstructorsTypeOfResearch(typeOfResearch: Constructors_Additional): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  deleteConstructorsTypeOfResearch(id: number): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsDiagnose(): Promise<BaseResponse<Array<any>>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsMedication(): Promise<BaseResponse<Array<any>>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsNonMedication(): Promise<BaseResponse<Array<any>>> {
    throw new Error('Method not implemented.');
  }

  getConstructorsObjectiveStatus(): Promise<BaseResponse<Array<any>>> {
    throw new Error('Method not implemented.');
  }

  getUniversityLevel(): Promise<BaseResponse<Array<University_Level>>> {
    throw new Error('Method not implemented.');
  }

  getPatientModel(): Promise<BaseResponse<Array<Patient_Model>>> {
    throw new Error('Method not implemented.');
  }

  getTypeOfPatientStatus(): Promise<BaseResponse<Array<Type_Of_Patient_Status>>> {
    throw new Error('Method not implemented.');
  }

  getCountPatient(startTime: string, endTime: string): Promise<BaseResponse<number>> {
    throw new Error('Method not implemented.');
  }

  countCompleteTask(startTime: string, endTime: string): Promise<BaseResponse<number>> {
    throw new Error('Method not implemented.');
  }

  countCompleteFailedTask(startTime: string, endTime: string): Promise<BaseResponse<number>> {
    throw new Error('Method not implemented.');
  }

  getBestUsers(startTime: string, endTime: string): Promise<BaseResponse<Array<Rating_BestUser>>> {
    throw new Error('Method not implemented.');
  }

  getUsergraphicLine(startTime: string, endTime: string): Promise<BaseResponse<Array<Rating_Line>>> {
    throw new Error('Method not implemented.');
  }

  getActivities(startTime: string, endTime: string): Promise<BaseResponse<Array<Rating_Activity>>> {
    throw new Error('Method not implemented.');
  }

  getDietMedicationsResults(): Promise<BaseResponse<MedicalResult_ResponseData>> {
    throw new Error('Method not implemented.');
  }

  getFoodMedicationsResults(): Promise<BaseResponse<MedicalResult_ResponseData>> {
    throw new Error('Method not implemented.');
  }

  getScheduleMedicationsResults(): Promise<BaseResponse<MedicalResult_ResponseData>> {
    throw new Error('Method not implemented.');
  }

  getActiveMedicationsResults(): Promise<BaseResponse<MedicalResult_ResponseData>> {
    throw new Error('Method not implemented.');
  }

  getPassiveMedicationsResults(): Promise<BaseResponse<MedicalResult_ResponseData>> {
    throw new Error('Method not implemented.');
  }

  getAlternativeTreatment(): Promise<BaseResponse<PassiveActiveTreatment_GetResponse_NetData[]>> {
    throw new Error('Method not implemented.');
  }

  getPassiveMedications(): Promise<BaseResponse<PassiveMedication_GetResponse_NetData[]>> {
    throw new Error('Method not implemented.');
  }

  createActiveMedication(data: ActiveMedication_CreateUpdate_NetData): Promise<BaseResponse<void>> {
    throw new Error('Method not implemented.');
  }

  updateActiveMedication(id: number, data: ActiveMedication_CreateUpdate_NetData): Promise<BaseResponse<void>> {
    throw new Error('Method not implemented.');
  }

  getActiveMedications(): Promise<BaseResponse<Array<ActiveMedication_GetResponse_NetData>>> {
    throw new Error('Method not implemented.');
  }

  createPassiveMedication(data: PassiveMedication_CreateUpdate_NetData): Promise<BaseResponse<void>> {
    throw new Error('Method not implemented.');
  }

  updatePassiveMedication(id: number, data: PassiveMedication_CreateUpdate_NetData): Promise<BaseResponse<void>> {
    throw new Error('Method not implemented.');
  }

  getAlternativeMedicationType(): Promise<BaseResponse<Array<AlternativeMedicationType_GetResponse>>> {
    throw new Error('Method not implemented.');
  }

  createFoodMedication(data: FoodMedication_CreateUpdate_NetData): Promise<BaseResponse<void>> {
    throw new Error('Method not implemented.');
  }

  updateFoodMedication(id: number, data: FoodMedication_CreateUpdate_NetData): Promise<BaseResponse<void>> {
    throw new Error('Method not implemented.');
  }

  getTargetUseTypes(): Promise<BaseResponse<Array<TargetUseType_NetData>>> {
    throw new Error('Method not implemented.');
  }

  updateDietMedication(id: number, data: DietMedication_CreateUpdateRequest_NetData): Promise<BaseResponse<void>> {
    throw new Error('Method not implemented.');
  }
  deleteDietMedication(id: number): Promise<BaseResponse<void>> {
    throw new Error('Method not implemented.');
  }

  getDietMedicalItems(): Promise<BaseResponse<DietMedication_GetResponse_NetData[]>> {
    throw new Error('Method not implemented.');
  }

  updateScheduleMedication(id: number, data: ScheduleMedication_CreateUpdate_NetData): Promise<BaseResponse<void>> {
    throw new Error('Method not implemented.');
  }

  async createScheduleMedication(data: ScheduleMedication_CreateUpdate_NetData): Promise<BaseResponse<void>> {
    throw new Error('Method not implemented.');
  }

  async getScheduleMedicationItems(): Promise<BaseResponse<ScheduleMedication_GetResponse_NetData[]>> {
    throw new Error('Method not implemented.');
  }

  async createDietMedication(diet: DietMedication_CreateUpdateRequest_NetData): Promise<BaseResponse<void>> {
    throw new Error('Method not implemented.');
  }

  async increaseToTherapyTaskStage(): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  async getNotMedicalMedication(taskProceedId: number): Promise<BaseResponse<Array<NotMedicalMedication_NetData>>> {
    throw new Error('Method not implemented.');
  }

  async createNotMedicalMedication(medication: NotMedicalMedication_NetData): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  async editNotMedicalMedication(id: number, medication: NotMedicalMedication_NetData): Promise<BaseResponse<any>> {
    throw new Error('Method not implemented.');
  }

  async getNotMedicalFlags(): Promise<BaseResponse<Array<NotMedicalFlag_ResponseData>>> {
    return wrapToResponse([]);
  }

  async getNotMedicalTypes(): Promise<BaseResponse<Array<NotMedicalType_ResponseData>>> {
    return wrapToResponse([]);
  }

  async getDietTypes(): Promise<BaseResponse<Array<DietType_ResponseData>>> {
    throw new Error('Method not implemented.');
  }

  async getFoodMedicationsItems(): Promise<BaseResponse<Array<FoodMedication_GetResponse_NetData>>> {
    throw new Error('Method not implemented.');
  }

  async getDosageUnits(): Promise<BaseResponse<Array<DosageUnit_ResponseData>>> {
    throw new Error('Method not implemented.');
  }

  async editPrescribedMedication(id: number, medication: PrescribedMedication__NetData): Promise<BaseResponse<any>> {
    return wrapToResponse(medication);
  }

  async getHint(): Promise<BaseResponse<string>> {
    throw new Error('Method not implemented.');
  }

  async getMedicalResult(): Promise<BaseResponse<MedicalResult_ResponseData>> {
    return wrapToResponse({
      medicationResults: [
        {
          description: '',
          points: 100,
          id: 1,
          correct: true,
        },
      ],
      negativePoints: 1,
      positivePoints: 1,
      success: false,
    });
  }

  async getNotMedicalResult(): Promise<BaseResponse<PrescribedMedicationResult__ResponseData[]>> {
    return wrapToResponse([]);
  }

  async createPrescribedMedication(medication: PrescribedMedication__NetData): Promise<BaseResponse<any>> {
    return wrapToResponse(medication);
  }

  async getPrescribedMedicationByTaskId(taskId: number): Promise<BaseResponse<Array<PrescribedMedication__ResponseData>>> {
    return wrapToResponse([
      {
        active: true,
        activeIngredient: 'string',
        additionalRestrictionId: 1,
        connectionWithFoodId: 1,
        connectionWithSleepId: 1,
        coursePeriodId: 1,
        createdatetime: 'string',
        deletedatetime: null,
        dosage: 1,
        groupOfMedicineId: 1,
        id: 1,
        numberOfDoses: 1,
        quantityCourse: 1,
        quantityPerDay: 1,
        realeseFormId: 1,
        receptionMethodId: 1,
        timeOfReceiptId: 1,
        totalDoses: 1,
        updatedatetime: 'string',
        userTaskProceedId: 1,
        dosageunitid: 1,
        factor: 1,
        situation_id: 1,
        situation: false,
      },
    ]);
  }

  async getReceptionPeriod(): Promise<BaseResponse<Array<ReceptionPeriod_NetData>>> {
    return wrapToResponse([]);
  }

  async getAdditionalRestriction(): Promise<BaseResponse<Array<AdditionalRestriction>>> {
    return wrapToResponse([]);
  }

  async getConnectionWithSleep(): Promise<BaseResponse<Array<ConnectionWithSleep>>> {
    return wrapToResponse([]);
  }

  async getConnectionWithFoods(): Promise<BaseResponse<Array<ConnectionWithFood>>> {
    return wrapToResponse([]);
  }

  async getTimesOfReceipt(): Promise<BaseResponse<Array<TimeOfReceipt>>> {
    return wrapToResponse([]);
  }

  async getReceptionMethods(): Promise<BaseResponse<Array<ReceptionMethod>>> {
    return wrapToResponse([]);
  }

  async getGroupOfMedicine(): Promise<BaseResponse<Array<GroupOfMedicine>>> {
    return wrapToResponse([]);
  }

  async getReleaseForms(): Promise<BaseResponse<ReleaseForm[]>> {
    return wrapToResponse([]);
  }

  async searchResearch(groupId: number, text: string): Promise<BaseResponse<TypeOfResearchSearchResult_Response>> {
    return wrapToResponse({} as TypeOfResearchSearchResult_Response);
  }

  async getDiagnosisForms(): Promise<BaseResponse<DiagnosisFrom_ResponseData>> {
    return wrapToResponse([]);
  }

  async increaseTaskStage(): Promise<BaseResponse<any>> {
    return wrapToResponse({});
  }

  async increaseSetTaskStage(): Promise<BaseResponse<any>> {
    return wrapToResponse({});
  }

  async pushDisgnosisResult(reqData: Diagnosis_ReqData): Promise<BaseResponse<any>> {
    return wrapToResponse({});
  }

  async getDiagnosisTypes(): Promise<BaseResponse<Array<DiagnosisTypes_ResponseData>>> {
    return wrapToResponse([]);
  }

  async getConstructorsClinicalDiagnosisAll(): Promise<BaseResponse<Array<Constructors_ClinicalDiagnosis>>> {
    return wrapToResponse([]);
  }
  async createConstructorsClinicalDiagnosis(): Promise<BaseResponse<Array<Constructors_ClinicalDiagnosis>>> {
    return wrapToResponse([]);
  }

  async updateConstructorsClinicalDiagnosis(): Promise<BaseResponse<Array<Constructors_ClinicalDiagnosis>>> {
    return wrapToResponse([]);
  }

  async deleteConstructorsClinicalDiagnosis(): Promise<BaseResponse<Array<Constructors_ClinicalDiagnosis>>> {
    return wrapToResponse([]);
  }

  async getConstructorsClinicalDiagnosisMedicalDictionary(id: number): Promise<BaseResponse<Array<Constructors_ClinicalDiagnosisMedicalDictionary>>> {
    return wrapToResponse([]);
  }
  async createConstructorsClinicalDiagnosisMedicalDictionary(): Promise<BaseResponse<Array<Constructors_ClinicalDiagnosisMedicalDictionary>>> {
    return wrapToResponse([]);
  }

  async updateConstructorsClinicalDiagnosisMedicalDictionary(): Promise<BaseResponse<Array<Constructors_ClinicalDiagnosisMedicalDictionary>>> {
    return wrapToResponse([]);
  }
  async getConstructorsClinicalDiagnosisInformationLevel(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getByTaskConstructorsClinicalDiagnosisInformationLevel(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async createConstructorsClinicalDiagnosisInformationLevel(): Promise<BaseResponse<Array<Constructors_ClinicalDiagnosisInformationLevel>>> {
    return wrapToResponse([]);
  }

  async updateByTaskConstructorsClinicalDiagnosisInformationLevel(): Promise<BaseResponse<Array<Constructors_ClinicalDiagnosisInformationLevel>>> {
    return wrapToResponse([]);
  }

  async deleteByTaskConstructorsClinicalDiagnosisInformationLevel(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async createClinicalDiagnosisTaskProceed(): Promise<BaseResponse<Array<ClinicalDiagnosisTaskProceed>>> {
    return wrapToResponse([]);
  }

  async getClinicalDiagnosisTaskProceed(): Promise<BaseResponse<Array<ClinicalDiagnosisTaskProceed>>> {
    return wrapToResponse([]);
  }


  async getTaskResults(): Promise<BaseResponse<TaskResult_ResponseData>> {
    return wrapToResponse({} as TaskResult_ResponseData);
  }

  async updateConstructorReferenceValue(): Promise<BaseResponse<any>>{
    return wrapToResponse([]);
  }

  async getCurrrentTaskResearchResults(): Promise<BaseResponse<ResearchResults_ResponseData[]>> {
    await delay(1000);

    return wrapToResponse(range(0, 20).map(it => researchResultGenerator.next(it).value as ResearchResults_ResponseData));
  }

  async getGroupOfResearchWithTypes(groupId: number): Promise<BaseResponse<GroupOfResearch_ResponseData>> {
    await delay(1500);
    return wrapToResponse({
      id: 1,
      active: true,
      name: "text",
      description: 'text',
      isChildFree: false,
      isGroupExist: true,
      isTypeExist: false,
      orderAt: 1,
      parentId: 0,
      typeOfResearch: [],
    });
  }

  async getGroupsOfResearchByParentId(parentId: number): Promise<BaseResponse<Array<GroupOfResearch_ResponseData>>> {
    await delay(1500);
    return wrapToResponse([
      {
        id: 1,
        active: true,
        name: "text",
        description: 'laboratory',
        isChildFree: false,
        isGroupExist: true,
        isTypeExist: false,
        orderAt: 1,
        parentId: 0,
        typeOfResearch: [],
      },
      {
        id: 1,
        active: true,
        name: "text",
        description: 'instrumental',
        isChildFree: false,
        isGroupExist: true,
        isTypeExist: false,
        orderAt: 1,
        parentId: 0,
        typeOfResearch: [],
      },
    ]);
  }

  async checkObjectiveState(message: string): Promise<BaseResponse<SendMessage_ResponseData>> {
    await delay(1500);
    return wrapToResponse({
      text: 'https://picsum.photos/200/300',
      points: 40,
      penalty: 30,
      tdMediaId: 1
    });
  }

  async loadCurrentCheckHistory(): Promise<BaseResponse<Array<CheckJournalHistory_ResponseData>>> {
    await delay(1500);
    return wrapToResponse(generateRandomCheckHistory());
  }

  async loadTaskById(id: number): Promise<BaseResponse<Task_ResponseData>> {
    return wrapToResponse(task);
  }

  async unfinishedTask(): Promise<BaseResponse<TaskProceed_ResponseData>> {
    await delay(1500);
    return wrapToResponse({
      id: 1,
      taskId: 1,
      testingStageId: TestingStage.HEALING,
      task: {
        active: true,
        created: '',
        creator: '',
        description: 'string',
        id: 1,
        name: 'string',
        penalty: 1,
        typeOfDifficultLevelId: 1,
        uid: '123',
        conscious: true,
        medicineId: 1,
        typeOfDifficultLevel: {
          active: true,
          code: 'code',
          coefficient: 1,
          id: 1,
          name: 'name',
        },
        patientModel: {
          patient: {
            firstName: 'string',
            secondName: 'string',
            photo: 'string',
            id: 1,
            gender: 'string',
          },
        },
      },
    });
  }

  async finishTask(): Promise<BaseResponse<TaskProceed_ResponseData>> {
    await delay(1500);
    return wrapToResponse({ id: 1, taskId: 1, testingStageId: 1, task: {} as Task_ResponseData });
  }

  async startTask(taskId: number): Promise<BaseResponse<TaskProceed_ResponseData>> {
    await delay(1500);
    return wrapToResponse({ id: taskId, taskId: 1, testingStageId: 1, task: {} as Task_ResponseData });
  }

  async startTask1(taskId: number, championship_id?: number): Promise<BaseResponse<TaskProceed_ResponseData>> {
    await delay(1500);
    return wrapToResponse({ id: taskId, taskId: 1, testingStageId: 1, task: {} as Task_ResponseData });
  }

  async getCurrentJournalHistory(): Promise<BaseResponse<Array<JournalHistory_ResponseData>>> {
    await delay(1500);
    return wrapToResponse(generateRandomChatHistory());
  }

  async registration(request: RegistrationRequest): Promise<void> {
    await delay(1000);
    return;
  }

  async authenticate(request: AuthenticateRequest): Promise<AxiosResponse<JwtToken>> {
    await delay(1000);
    return { data: { id_token: 'token1' } } as AxiosResponse;
  }

  async sendMessage(message: string): Promise<BaseResponse<SendMessage_ResponseData>> {
    await delay(1000);
    return {
      success: true,
      description: null,
      result: {
        text: message,
        points: 40,
        penalty: 30,
        tdMediaId: 1
      },
    };
  }

  async getChatById(chatId: string): Promise<Array<ChatMessage>> {
    await delay(1000);
    return [];
  }

  async resetPasswordByEmail(email: string): Promise<BaseResponse<any>> {
    await delay(1000);
    return wrapToResponse({});
  }

  async setNewPassword(password: string): Promise<void> {
    await delay(1000);
    return;
  }

  async updateUserProfile(name: string, lastName: string, phone: string): Promise<void> {
    await delay(1000);
    return;
  }

  async loadMedicine(): Promise<BaseResponse<Array<Medicine_ResponseData>>> {
    await delay(1000);
    return {
      success: true,
      result: [
        {
          name: 'name',
          active: true,
          id: 0,
          description: 'desc',
          orderBy: 1,
          uid: 'uid',
        },
      ],
    };
  }

  async loadTypesOfDifficultLevels(): Promise<BaseResponse<Array<TypeOfDifficultLevel_ResponseData>>> {
    await delay(1000);
    return {
      success: true,
      result: [
        { id: 1, active: true, code: 'H1', coefficient: 1, name: 'Легко' },
        { id: 2, active: true, code: 'H2', coefficient: 2, name: 'Средне' },
        { id: 3, active: true, code: 'H3', coefficient: 3, name: 'Сложно' },
        {
          id: 0,
          active: true,
          code: 'Default',
          coefficient: 0,
          name: 'Случайно',
        },
      ],
    };
  }

  loadIllnessSeverity(): Promise<BaseResponse<Array<any>>> {
    throw new Error('Method not implemented.');
  }

  loadIllnessRisk(): Promise<BaseResponse<Array<any>>> {
    throw new Error('Method not implemented.');
  }

  loadIllnessStage(): Promise<BaseResponse<Array<any>>> {
    throw new Error('Method not implemented.');
  }

  loadIllnessPhase(): Promise<BaseResponse<Array<any>>> {
    throw new Error('Method not implemented.');
  }

  async loadTasks(): Promise<BaseResponse<Array<Task_ResponseData>>> {
    return {
      success: true,
      result: [task],
    };
  }

  async loadTasksBy(universityLevelId: number, medicineId: number, typeOfDifficultLevelId: number): Promise<BaseResponse<Array<Task_ResponseData>>> {
    return {
      success: true,
      result: [task],
    };
  }

  async getResearchResults(researches: Array<ResearchItem_RequestData>): Promise<BaseResponse<Array<ResearchResults_ResponseData>>> {
    return wrapToResponse([]);
  }

  async getTest(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async setTestAnswers(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getAnamnesis(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getClinicalRelevance(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getConnectionWithIllness(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getDefaultMeanList(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async createMean(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getAnamnesisType(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async updateMeaning(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async deleteMeaning(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getPatientConscious(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getPatientState(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getObjectiveStatus(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async createObjectiveStatus(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getCurrentObjectiveStatus(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getExcrementPhoto(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getExcrementComfort(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getExcrementColor(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getExcrementSmell(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getUrinePressure(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getUrineColor(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getUrineSmell(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getUrineImpurity(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getUrineTransparency(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getUrineFoam(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getUrineComfort(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getUrineAdditionalFenomen(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getAllAnamnesisList(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getInspection(): Promise<any> {
    return wrapToResponse([]);
  }

  async getPartOfBody(): Promise<any> {
    return wrapToResponse([]);
  }

  async getFrontBodyPartImage(): Promise<any> {
    return wrapToResponse([]);
  }

  async getInspectionSide(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getLocalisationsPlace(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getStandardAlternativeMedication(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getStandardMedication(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getConstructorAdditionalResults(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getConstructorPastResearch(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getConstructorPastSpecialist(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async updateConstructorPastResearch(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async updateConstructorPastSpecialist(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async updateCurrentObjectiveStatus(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getTaskProceedPastResearch(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getMedicalDictionaryAll(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getTaskProceedPastSpecialist(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getReference(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getMedicalDictionaryFromId(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getMedicationDictionaryByText(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async createCustomPointOfView(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async updateCustomPointOfView(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async deleteCustomPointOfView(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async updateConstructorAdditionalResult(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async deleteConstructorAdditionalResult(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async createSchedule(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async updateSchedule(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async createDiet(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async updateDiet(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async createNotTreatmentPassive(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async updateNotTreatmentPassive(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async createNotTreatmentActive(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async updateNotTreatmentActive(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async deleteSchedule(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async updateMedicationTreatment(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async deleteNotTreatmentActive(id: number): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async deleteMedicationTreatment(id: number): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async deleteNotTreatmentPassive(id: number): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async createMedicationTreatment(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async deleteDiet(id: number): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async createResearchInstrument(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async updateResearchInstrument(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async createResearchLab(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async updateResearchLab(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getSituationList(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getRandomTask(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getCompetenceAll(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getCurrentCompetence(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async deleteCompetence(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async updateCompetence(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async createCompetence(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getAllSpecialties(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getCurrentSpecialties(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async deleteCurrentSpecialties(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async createCurrentSpecialties(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async updateCurrentSpecialties(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getTypeOfMedicalDictionaryAll(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async createTypeMedicalDictionary(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async deleteTypeMedicalDictionary(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async updateTypeMedicalDictionary(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getAllTypeMedicalDictionaryList(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getAllMedicalDictionaryList(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async createMedicalDictionary(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async deleteMedicalDictionary(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async updateMedicalDictionary(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getModesAll(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async createMode(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async updateMode(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async deleteMode(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getNursingCareAll(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async updateNursingCare(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async createNursingCare(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async deleteNursingCare(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getSurgeonTreatment(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async updateSurgeonTreatment(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async createSurgeonTreatment(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async deleteSurgeonTreatment(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getModulesAll(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async updateModules(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async createModules(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async deleteModules(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getNoneMedicalTreatmentAll(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async createNoneMedicalTreatment(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async updateNoneMedicalTreatment(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async deleteNoneMedicalTreatment(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getMatrixCompetenceAll(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async createMatrixCompetence(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async getNoneMedicalTreatmentTypeAll(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async createNoneMedicalTreatmentType(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async updateNoneMedicalTreatmentType(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async deleteNoneMedicalTreatmentType(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
  async depersonalizedRatingData(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getAllDoctorsType(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getNursingCareResult(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async createNursingCareTreatment(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getNursingCareAlternativeList(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async updateConstructorNursingCare(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async updateConstructorSurgeonTreatment(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async createConstructorSurgeonTreatment(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async deleteConstructorSurgeonTreatment(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getTreatmentSchemeById(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async createTreatmentScheme(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async deleteTreatmentScheme(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async updateTreatmentScheme(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getAllTreatmentScheme(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getAllGroupOfResearch(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getCurrentGroupOfResearch(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async createResearchElement(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async createResearchGroup(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async deleteResearchGroup(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async updateResearchGroup(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async deleteCurrentGroupOfResearch(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async teacherReviewGetAll(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async teacherReviewCreate(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async deleteReviewTeacher(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getAllPatients(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async createPatient(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async updatePatient(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getAllModel(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async deletePatientModel(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async createModel(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async updateModel(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getPatientModelParameters(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async createPatientModelParameters(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async deletePatientModelParameters(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async finishTaskConstructor(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getNoGroupType(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getSpecialistsAdvice(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getSpecialistAdviceByTask(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async createSpecialistAdvice(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async deleteSpecialistAdvice(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async updateSpecialistAdvice(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async deleteDietInConstructorTask(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async deleteNursingByTaskCare(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async createNursingByTaskCare(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getAllQuestions(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async createQuestion(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async updateQuestion(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async deleteQuestion(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getCurrentAnswers(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async createAnswer(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async deleteAnswer(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getQuestionsOfTask(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async setQuestionForTask(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async deleteQuestionTask(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getStressCheck(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async changeStressCheck(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async changeCognitiveCheck(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getAdminUser(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getTaskDetail(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }

  async setReviewLog(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getPilotingTask(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getPatientModelVoiceResource(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getPatientModelVoiceResourceById(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async createPatientModelVoice(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async updatePatientModelVoiceResource(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async deletePatientModelVoiceResource(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async resetPasswordFinish(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }

  async generateEmailCode(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }

  async sendTestResultOnEmail(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getListOfStages(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getTestingStageHistory(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }

  async setTestingStageHistory(id: number): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getTypeOfDifficultLevel(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }

  async copyTaskOnConstructor(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getDoctorsInfoFilteringTests(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async createLegendOption(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async updateLegendOption(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async deleteLegendOption(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getLegendOption(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getThemesAll(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getCurrentTheme(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async deleteTheme(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async createTheme(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async updateTheme(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getAllRecipe(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getRecipeUserTaskProceed(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async createRecipeUserTaskProceed(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getRecipeUserTaskProceedActive(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getRecipeDetailsTypes(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async createRecipeTaskDetail(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async deleteRecipeTaskDetail(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getRecipeTaskDetailByProceedTask(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getRecipeTaskDetailByTask(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async updateRecipeTaskDetail(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async createRecipeTask(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async deleteRecipeTask(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async createRecipeUserTaskProceedActive(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getRecipeTaskById(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async getRecipeTask(): Promise<BaseResponse<any>> {
    return wrapToResponse([])
  }
  async createDiagnosticCriteria(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async updateDiagnosticCriteria(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async deleteDiagnosticCriteria(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getDiagnosticCriteria(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getAllDiagnosticCriteria(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  // Работа с проявлениями диагностического критерия
  async createDiagnosticCriteriaDescription(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async updateDiagnosticCriteriaDescription(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async deleteDiagnosticCriteriaDescription(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getDiagnosticCriteriaDescription(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getAllDiagnosticCriteriaDescriptions(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  // Работа с баллами диагностического критерия
  async createDiagnosticCriteriaPointsDescription(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async updateDiagnosticCriteriaPointsDescription(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async deleteDiagnosticCriteriaPointsDescription(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getDiagnosticCriteriaPointsDescription(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getAllDiagnosticCriteriaPointsDescriptions(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  // Работа с диагностическими задачами
  async createDiagnosticCriteriaTask(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async updateDiagnosticCriteriaTask(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async deleteDiagnosticCriteriaTask(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getDiagnosticCriteriaTask(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getAllDiagnosticCriteriaTasks(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  // Работа пользователя с критериями
  async createDiagnosticCriteriaUserTask(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async updateDiagnosticCriteriaUserTask(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async deleteDiagnosticCriteriaUserTask(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getDiagnosticCriteriaUserTask(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getAllDiagnosticCriteriaUserTasks(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getDiagnosticCriteriaUserTaskResult(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }

  async getDiagnosticCriteriaUserTaskTextResult(): Promise<BaseResponse<any>> {
    return wrapToResponse([]);
  }
}