import React, { useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Boy11New(props) {
  const { nodes, materials, animations } = useGLTF('patient/BOY_11_years_animation20122024.glb')
  const { ref, actions, names, mixer } = useAnimations(animations)

  useEffect(() => {
    props.setAnim(animations)
    actions.rigAction.play()
  }, [actions, names, mixer])

  return (
    <group ref={ref} {...props} dispose={null}>
      <group position={[0, 0.63, -0.01]} rotation={[0.25, 0, 0]}>
        <group position={[0, 0.1, 0]} rotation={[-0.13, 0, 0]}>
          <group position={[0, 0.09, 0]} rotation={[-0.4, 0, 0]}>
            <group position={[0, 0.11, 0]} rotation={[0.36, 0, 0]}>
              <group position={[0, 0.12, 0]} rotation={[0.59, 0, 0]}>
                <group position={[0, 0.03, 0]} rotation={[-0.05, 0.03, 0.27]}>
                  <group position={[0, 0.03, 0]} rotation={[-0.58, 0.13, -0.24]}>
                    <group position={[0.01, 0.05, 0.03]} rotation={[-0.09, 0, 0]}>
                      <group position={[0, 0.02, 0]}>
                        <group position={[0, 0.02, 0]}>
                          <group position={[0, 0.02, 0]}>
                            <group position={[0, 0, 0.06]} rotation={[3.14, -0.01, 0.01]}>
                              <group position={[0, 0.03, 0]} rotation={[-0.47, 0.02, -0.02]}>
                                <group position={[0, 0.02, 0]} rotation={[1.16, -0.06, 0.02]}>
                                  <group rotation={[0.22, 0.12, 0.03]} />
                                </group>
                              </group>
                            </group>
                            <group position={[0, -0.06, 0.06]} rotation={[-1.5, 0.03, -1.2]} />
                            <group position={[0, -0.07, 0.06]} rotation={[-1.41, -0.03, -1.3]} />
                            <group position={[0, -0.1, 0.01]} rotation={[1.59, 0, -0.01]}>
                              <group position={[0, 0.04, 0]} rotation={[-0.7, 0, 0.02]} />
                            </group>
                            <group position={[0.06, -0.04, -0.02]} rotation={[-0.17, -0.18, -0.26]}>
                              <group position={[0, 0.03, 0]} rotation={[-0.71, -0.14, -0.08]}>
                                <group position={[0, 0.01, 0]} rotation={[-1.71, -0.4, 0.06]}>
                                  <group position={[0, 0.02, 0]} rotation={[-0.88, -0.7, 0.23]} />
                                </group>
                              </group>
                            </group>
                            <group position={[-0.06, -0.04, -0.02]} rotation={[-0.17, 0.18, 0.25]}>
                              <group position={[0, 0.03, 0]} rotation={[-0.71, 0.14, 0.08]}>
                                <group position={[0, 0.01, 0]} rotation={[-1.71, 0.4, -0.06]}>
                                  <group position={[0, 0.02, 0]} rotation={[-0.88, 0.7, -0.23]} />
                                </group>
                              </group>
                            </group>
                            <group position={[0, -0.06, 0.06]} rotation={[-1.5, -0.02, 1.2]} />
                            <group position={[0, -0.07, 0.06]} rotation={[-1.41, 0.03, 1.3]} />
                            <group position={[0.04, 0, 0.04]} rotation={[-0.83, 0.84, 1.93]}>
                              <group position={[0, 0.01, 0]} rotation={[-0.25, 0.74, -0.09]}>
                                <group position={[0, 0.01, 0]} rotation={[-0.18, 0.4, -0.21]} />
                              </group>
                            </group>
                            <group position={[0.04, -0.01, 0.04]} rotation={[-0.88, 1.12, 1.99]}>
                              <group position={[0, 0.01, 0]} rotation={[-0.4, 0.79, -0.37]}>
                                <group position={[0, 0.01, 0]} rotation={[-0.38, 0.28, -0.36]}>
                                  <group position={[0, 0.01, 0]} rotation={[-0.41, 0.66, -0.21]}>
                                    <group position={[0, 0.01, 0]} rotation={[0.99, -0.15, -2.43]}>
                                      <group position={[0, 0.01, 0]} rotation={[0.27, 0.14, -0.02]}>
                                        <group position={[0, 0.01, 0]} rotation={[0.32, 0.31, 0.39]} />
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                            <group position={[-0.04, 0, 0.04]} rotation={[-0.84, -0.83, -1.94]}>
                              <group position={[0, 0.01, 0]} rotation={[-0.25, -0.74, 0.09]}>
                                <group position={[0, 0.01, 0]} rotation={[-0.18, -0.4, 0.21]} />
                              </group>
                            </group>
                            <group position={[-0.04, -0.01, 0.04]} rotation={[-0.89, -1.12, -2]}>
                              <group position={[0, 0.01, 0]} rotation={[-0.4, -0.79, 0.37]}>
                                <group position={[0, 0.01, 0]} rotation={[-0.38, -0.28, 0.36]}>
                                  <group position={[0, 0.01, 0]} rotation={[-0.41, -0.66, 0.21]}>
                                    <group position={[0, 0.01, 0]} rotation={[0.99, 0.15, 2.43]}>
                                      <group position={[0, 0.01, 0]} rotation={[0.27, -0.14, 0.02]}>
                                        <group position={[0, 0.01, 0]} rotation={[0.32, -0.31, -0.39]} />
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                            <group position={[0.01, 0.04, 0.06]} rotation={[-1.94, 1.4, -1.34]}>
                              <group position={[-0.01, 0, 0.02]} rotation={[0.05, -0.27, 0.03]}>
                                <group position={[-0.01, 0.01, 0.02]} rotation={[0.17, -0.53, 0.23]}>
                                  <group position={[-0.01, 0.02, 0.04]} rotation={[0.08, -0.67, 0.32]}>
                                    <group position={[0, 0.05, 0]} rotation={[-0.27, -0.24, 0.23]}>
                                      <group position={[0, 0.05, 0]} rotation={[-0.24, -0.7, 0.61]}>
                                        <group position={[0, 0.04, 0]} rotation={[-1.9, 0.32, 1.54]}>
                                          <group position={[0, 0.03, 0]} rotation={[-1.86, 0.02, -0.67]}>
                                            <group position={[0, 0.04, 0]} rotation={[0.81, 0.64, 0.12]}>
                                              <group position={[0, 0.04, 0]} rotation={[0.67, 0.53, 0.3]}>
                                                <group position={[0, 0.02, 0]} rotation={[-2.11, 0.92, 2.22]}>
                                                  <group position={[0, 0.02, 0]} rotation={[-0.52, 0.86, -0.26]} />
                                                </group>
                                              </group>
                                            </group>
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                            <group position={[-0.01, 0.04, 0.06]} rotation={[-1.93, -1.39, 1.36]}>
                              <group position={[0.01, 0, 0.02]} rotation={[0.05, 0.27, -0.03]}>
                                <group position={[0.01, 0.01, 0.02]} rotation={[0.17, 0.53, -0.23]}>
                                  <group position={[0.01, 0.02, 0.04]} rotation={[0.08, 0.67, -0.32]}>
                                    <group position={[0, 0.05, 0]} rotation={[-0.27, 0.24, -0.23]}>
                                      <group position={[0, 0.05, 0]} rotation={[-0.24, 0.7, -0.61]}>
                                        <group position={[0, 0.04, 0]} rotation={[-1.9, -0.32, -1.54]}>
                                          <group position={[0, 0.03, 0]} rotation={[-1.86, -0.02, 0.67]}>
                                            <group position={[0, 0.04, 0]} rotation={[0.81, -0.64, -0.12]}>
                                              <group position={[0, 0.04, 0]} rotation={[0.67, -0.53, -0.3]}>
                                                <group position={[0, 0.02, 0]} rotation={[-2.11, -0.92, -2.22]}>
                                                  <group position={[0, 0.02, 0]} rotation={[-0.52, -0.86, 0.26]} />
                                                </group>
                                              </group>
                                            </group>
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                            <group position={[0.05, -0.01, 0.03]} rotation={[-2.97, -0.86, 0.96]}>
                              <group position={[0, 0.04, 0]} rotation={[2.57, -0.63, 1.39]}>
                                <group position={[0, 0.02, 0]} rotation={[2.64, 0.65, 1.5]} />
                              </group>
                            </group>
                            <group position={[-0.05, -0.01, 0.03]} rotation={[-2.98, 0.86, -0.95]}>
                              <group position={[0, 0.04, 0]} rotation={[2.57, 0.63, -1.39]}>
                                <group position={[0, 0.02, 0]} rotation={[2.64, -0.65, -1.5]} />
                              </group>
                            </group>
                            <group position={[0, -0.07, 0.04]} rotation={[-1.16, 0, 0]}>
                              <group position={[0, 0.02, 0]} rotation={[-0.37, 0, 0]} />
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[0.01, 0.1, 0.05]} rotation={[-1.6, -0.09, -1.11]}>
                <group position={[0.01, 0.12, -0.04]} rotation={[1.33, 1.2, -2.35]}>
                  <group position={[0, 0.18, 0]} rotation={[0.82, 0.2, 0.04]}>
                    <group position={[0, 0.19, 0]} rotation={[0.05, 0.07, -0.15]}>
                      <group position={[-0.01, 0.03, 0.01]} rotation={[-2.95, 1.49, 3.14]}>
                        <group position={[0, 0.04, 0]} rotation={[0.19, -0.23, 0.09]}>
                          <group position={[0, 0.02, 0]} rotation={[0.16, -0.13, -0.05]} />
                        </group>
                        <group position={[-0.01, -0.01, 0.01]} rotation={[-1.15, 0.89, 1.48]}>
                          <group position={[0, 0.02, 0]} rotation={[0.19, -0.17, 0.09]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.03, 0]} rotation={[2.12, 1.44, -2.12]}>
                        <group position={[0, 0.04, 0]} rotation={[0.14, -0.24, 0.01]}>
                          <group position={[0, 0.02, 0]} rotation={[0.12, -0.24, 0.05]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.03, -0.01]} rotation={[0.98, 1.46, -1.12]}>
                        <group position={[0, 0.04, 0]} rotation={[0.11, -0.21, -0.03]}>
                          <group position={[0, 0.02, 0]} rotation={[0.17, -0.12, -0.02]} />
                        </group>
                      </group>
                      <group position={[0, 0.03, -0.02]} rotation={[0.24, 1.47, -0.48]}>
                        <group position={[0, 0.04, 0]} rotation={[0.2, -0.31, -0.08]}>
                          <group position={[0, 0.01, 0]} rotation={[0.18, -0.05, -0.05]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[-0.01, 0.1, 0.05]} rotation={[-1.6, 0.09, 1.11]}>
                <group position={[-0.01, 0.12, -0.04]} rotation={[1.33, -1.2, 2.35]}>
                  <group position={[0, 0.18, 0]} rotation={[0.82, -0.2, -0.04]}>
                    <group position={[0, 0.19, 0]} rotation={[0.05, -0.07, 0.15]}>
                      <group position={[0.01, 0.03, 0.01]} rotation={[-2.95, -1.49, -3.14]}>
                        <group position={[0, 0.04, 0]} rotation={[0.19, 0.23, -0.09]}>
                          <group position={[0, 0.02, 0]} rotation={[0.16, 0.13, 0.05]} />
                        </group>
                        <group position={[0.01, -0.01, 0.01]} rotation={[-1.15, -0.89, -1.48]}>
                          <group position={[0, 0.02, 0]} rotation={[0.19, 0.17, -0.09]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.03, 0]} rotation={[2.12, -1.44, 2.12]}>
                        <group position={[0, 0.04, 0]} rotation={[0.14, 0.24, -0.01]}>
                          <group position={[0, 0.02, 0]} rotation={[0.12, 0.24, -0.05]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.03, -0.01]} rotation={[0.98, -1.46, 1.12]}>
                        <group position={[0, 0.04, 0]} rotation={[0.11, 0.21, 0.03]}>
                          <group position={[0, 0.02, 0]} rotation={[0.17, 0.12, 0.02]} />
                        </group>
                      </group>
                      <group position={[0, 0.03, -0.02]} rotation={[0.24, -1.47, 0.48]}>
                        <group position={[0, 0.04, 0]} rotation={[0.2, 0.31, 0.08]}>
                          <group position={[0, 0.01, 0]} rotation={[0.18, 0.05, 0.05]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
        <group position={[0.06, 0, 0.02]} rotation={[2.77, 0, -0.16]}>
          <group position={[0, 0.3, 0]} rotation={[0.24, 0.04, 0.01]}>
            <group position={[0, 0.27, 0]} rotation={[-1.05, -0.12, 0.07]} />
          </group>
        </group>
        <group position={[-0.06, 0, 0.02]} rotation={[2.77, 0, 0.16]}>
          <group position={[0, 0.3, 0]} rotation={[0.24, -0.04, -0.01]}>
            <group position={[0, 0.27, 0]} rotation={[-1.05, 0.12, -0.07]} />
          </group>
        </group>
      </group>
      <group name="rig">
        <primitive object={nodes.root} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
        <primitive object={nodes['MCH-torsoparent']} />
        <primitive object={nodes['MCH-hand_ikparentL']} />
        <primitive object={nodes['MCH-hand_ikparentR']} />
        <primitive object={nodes['MCH-foot_ikparentL']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
        <primitive object={nodes['MCH-foot_ikparentR']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
        <skinnedMesh
          geometry={nodes.boys_cloth.geometry}
          material={materials.sergey_cloth}
          skeleton={nodes.boys_cloth.skeleton}
        />
        <skinnedMesh
          geometry={nodes.eyelashes01Mesh.geometry}
          material={materials.eyelashes01}
          skeleton={nodes.eyelashes01Mesh.skeleton}
        />
        <skinnedMesh
          geometry={nodes['high-poly'].geometry}
          material={materials['high-poly']}
          skeleton={nodes['high-poly'].skeleton}
        />
        {/* <skinnedMesh
          geometry={nodes['high-poly_1'].geometry}
          material={materials.eyesglass}
          skeleton={nodes['high-poly_1'].skeleton}
        /> */}
        <skinnedMesh
          geometry={nodes.PETYA_BODY.geometry}
          material={materials.PETYA_BODY}
          skeleton={nodes.PETYA_BODY.skeleton}
        />
        <skinnedMesh
          geometry={nodes.short01Mesh.geometry}
          material={materials.short01}
          skeleton={nodes.short01Mesh.skeleton}
        />
      </group>
    </group>
  )
}
