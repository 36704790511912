import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import { MenuItemData, MenuItemDataSteps } from '../../../types';
import SidebarStep from './SidebarStep';
import { getTestingstageTaskOnTask } from '../../../api/chatApi/chatApi';
import chatApi from '../../../api/chatApi';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import useTestingStage from '../../../hooks/testingTask/useTestingStage';

interface LevelPageProps {
  menuItems?: MenuItemData[];
  nameStagesOnTask?: MenuItemDataSteps[];
}

const LevelPage: React.FC<LevelPageProps> = ({ menuItems = [], nameStagesOnTask = [] }) => {
  const history = useHistory();
  const location = useLocation();
  const [stages, setStages] = useState<MenuItemDataSteps[]>([]);
  const [lastStage, setLastStage] = useState<number>(0);
  const [loadMenuItems, setLoadMenuItems] = useState<boolean>(false);
  const currentStage = useTestingStage();
  const [currentLegendId, setCurrentLegendId] = useState<number | null>(null);

  useEffect(() => {
    if (currentLegendId === null && location.state) {
      setCurrentLegendId(location.state.legendId)
    }
  }, [stages])
  
  // Функция для загрузки всех этапов
  const fetchAllStages = async () => {
    try {
      const result = await getTestingstageTaskOnTask();
      setStages(result.data.testingstage_task_on_task || []);
    } catch (error) {
      console.error('Ошибка при загрузке этапов:', error);
    }
  }

  useEffect(() => {
    fetchAllStages();
  }, []);

  // Функция для получения истории этапов
  const getHistoryStage = async () => {
    setLoadMenuItems(true);
    try {
      const res = await chatApi.getTestingStageHistory();
      const lastActiveStageId = res.result.splice(-1, 1)[0]?.activeStageId || 0;
      setLastStage(lastActiveStageId);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadMenuItems(false);
    }
  }

  useEffect(() => {
    getHistoryStage();
  }, []);


  // Функция обработки клика по шагу
  const onClickStep = (menuItem: MenuItemData) => {
    if (currentStage >= menuItem.activeStage) {
      history.push(menuItem.routePath);
    }
  }

  // Функция обработки клика по новым шагам 
  const onClickNewSteps = (menuItem: MenuItemDataSteps) => {
    if (menuItem.routePath === '/legend') {
      const legendId = menuItem.legend_id || stages.find((stage) => stage.routePath === '/legend')?.legend_id;

      if (!legendId) {
        console.warn('Legend ID отсутствует для данного этапа');
        return;
      }

      history.push({
        pathname: menuItem.routePath,
        state: {
          legendId: menuItem.legend_id
        }
      });
    } else {
      history.push(menuItem.routePath);
    }
  };

  // Функция определения статуса этапа
  const getStatus = (it: MenuItemDataSteps, currentPath: string) => {
    if (it.routePath === currentPath) {
      if (it.routePath === '/legend') {
        if (currentLegendId === it.legend_id) {
          return 'active';
        } 
      } else {
        return 'active'; 
      }
    }
    // Если этап завершен
    if (it.activeStage <= lastStage) {
      return 'complete';
    }
    // Этап еще не доступен
    return 'inactive';
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const legendIdParam = params.get('legendId');
    if (legendIdParam) {
      setCurrentLegendId(Number(legendIdParam));
    } else {
      setCurrentLegendId(null);
    }
  }, [location.search]);

  if (loadMenuItems) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {nameStagesOnTask.length === 0 ? (
        menuItems.map((it, inx) => {
          let status: 'inactive' | 'active' | 'complete';
          if (lastStage >= it.activeStage) status = 'complete';
          else if (lastStage < it.activeStage) status = 'inactive';
          if (location.pathname === it.routePath) status = 'active';

          return (
            <SidebarStep
              number={inx + 1}
              key={inx + 1}
              title={it.title}
              status={status}
              onClick={() => onClickStep(it)}
            />
          );
        })
      ) : (
        nameStagesOnTask
          .sort((a, b) => a.activeStage - b.activeStage)
          .map((it, inx) => {
            let status = getStatus(it, location.pathname);
            return (
              <SidebarStep
                number={inx + 1}
                key={inx + 1}
                status={status}
                name={it.testingstage_descr}
                onClick={() => onClickNewSteps(it)}
              />
            );
          })
      )}
    </>
  );
};

export default LevelPage;
